<template>
  <v-timeline
      align-top
      dense
      v-if="items.length > 0"
  >
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-timeline-item
        color="primary"
        small
        v-for="item of items"
        :key="item.id"
    >
      <v-row class="pt-1">

        <v-col cols="3" v-if="!(checkedIds.checked && checkedIds.index === item.id)">
          {{ item.dateText }}
          <div class="text-caption">
            {{item.time}}
          </div>
        </v-col>
        <v-col cols="3" v-if="checkedIds.checked && checkedIds.index === item.id">
          <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :close-on-click="false"
              :nudge-right="40"
              :label="'Datum'"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="date"
                  :label="'Datum'"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="date"
                max-width="700"
                locale="de-de"
                @input="menu = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"

                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="time"
                  label="Uhrzeit"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
                v-model="time"
                label="Uhrzeit"
                format="24hr"
                max-width="700"
                @input="menu2 = false"
                @change="menu2 = false"
            ></v-time-picker>
          </v-menu>
        </v-col>
        <v-col v-if="!(checkedIds.checked && checkedIds.index === item.id)">
          <strong>

            <v-icon v-if="item.type === 'Notiz'" class="pe-1">mdi-note</v-icon>
            <v-icon v-if="item.type === 'Anruf'" class="pe-1">mdi-phone</v-icon>
            <v-icon v-if="item.type === 'Nachricht'" class="pe-1">mdi-mail</v-icon>
            {{ item.subject }}</strong>
          <div class="text-caption">
            {{ item.text }}
          </div>
        </v-col>
        <v-col v-if="checkedIds.checked && checkedIds.index === item.id">
          <v-text-field v-model="subject" label="Titel"></v-text-field>
          <v-text-field v-model="text" label="Text"></v-text-field>
          <v-autocomplete
              :items= "typeItems"
              v-model="type"
              label="Typ"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="2" sm="3" md="2" lg="1">
          <a class="pa-0 ma-0 ps-6"
             @click="checkedEntry({index: item.id, date: item.date, time: item.time, text: item.text, subject: item.subject, type: item.type})">
            <v-icon
                class="mt-4"
                v-if="checkedIds.index === item.id && checkedIds.checked === true && item.id"
            >
              mdi-cancel
            </v-icon>
            <v-icon
                v-if="!(checkedIds.checked && checkedIds.index === item.id) && item.id"
            >
              mdi-pencil
            </v-icon>
          </a>
        </v-col>
        <v-col cols="2" sm="3" md="1">
          <v-icon
              class="mt-4"
              v-if="checkedIds.index === item.id && checkedIds.checked === true && item.id"
              @click="editEntry(item.id); checkedEntry({index: '', date: '', time: '', text: '', subject: '', type: ''})"

          >
            mdi-check
          </v-icon>
          <v-icon v-else-if="!(checkedIds.checked && checkedIds.index === item.id) && item.id" @click="deleteEntry(item.id)">mdi-delete</v-icon>
        </v-col>
      </v-row>
    </v-timeline-item>
  </v-timeline>
  <NoResultSnippet
      v-else
      :text="noResultsText">
  </NoResultSnippet>
</template>

<script>
import {date2String, error, msgObj} from "@/helper/helper";
const Message = () => import("@/components/generalUI/Message");
const NoResultSnippet = () => import("@/components/generalUI/NoResultSnippet");
import{getTimeFromMillis} from "@/helper/helper";
import {mapGetters} from "vuex";

export default {
  name: "Message",
  components: {
    NoResultSnippet,
    Message
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('wedding', {
      wedding: 'wedding',
      weddingHistory: 'weddingHistory'
    }),
  },
  data() {
    return {
      message: msgObj(),
      checkedIds: {checked: false, index: ""},
      time: null,
      date: null,
      menu: false,
      menu2: false,
      typeItems:['Notiz', 'Anruf', 'Nachricht'],
      subject:'',
      text:'',
      title:'',
      type:'',

    }

  },
  props: {
    /**
     * Contains data to display the alert accordingly
     */
    items: {
      type: Array,
      default() {
        return []
      }
    },
    noResultsText: String
  },
  methods: {
    openTask(id) {
      this.$router.push('/planner/tasks/task/' + id)
    },
    getStringDate(date) {
      return date2String(date)
    },
    deleteEntry(id) {
      this.$store.dispatch('wedding/deleteHistoryEntry', {
        id,
      }).then(() => {
        this.$emit('deletedEntry')
      }).catch((err) => {
        this.$emit('error', err)
      })
    },
    checkedEntry(payload) {
      this.checkedIds = {checked: !this.checkedIds.checked, index: payload.index}
      this.date = getTimeFromMillis(payload.date)
      this.time = payload.time
      this.text = payload.text
      this.subject = payload.subject
      this.type = payload.type
    },
    editEntry(payload) {
       console.log("Test Until Here")
      console.log(this.user.id)
        this.$store.dispatch('wedding/editHistoryEntry', {
          plannerId: this.user.id,
          entryId: payload,
          date : new Date(this.date).getTime(),
          time : this.time,
          text: this.text,
          subject: this.subject,
          type: this.type
        }).then(() => {
          this.checkedIds.checked = false
          this.checkedIds.index = ''
          this.$store.dispatch('wedding/getWedding',{
            uid: this.user.id,
            weddingId: this.$route.params.id
          }).then(() => {
            this.checkedIds.checked = false
            this.checkedIds.index = ''
            this.$store.dispatch('wedding/getHistoryForWedding',{
              uid: this.user.id,
              weddingId: this.$route.params.id
            }).then(()=>{
                this.$store.dispatch('sort/sort', {
                  objectArray: this.weddingHistory,
                  sortField: 'date',
                  sortStyle: 'DESC'
                }).catch((err) => {
                  this.message = error(err)
                })
            }).catch((err) => {
              this.message = error(err)
            })
          }).catch((err) => {
            this.message = error(err)
          })
        }).catch((err) => {
          this.message = error(err)
        })
    }
  }
}
</script>
